<template>
  <div class="Page">
    <div class="opt-bar flex">
      <el-button @click="handleNew" size="small" v-ripple>新增</el-button>
      <el-input size="small"
          @keyup.enter.native="onCurrentPageChange(1)"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="onSkeyClear"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
      <el-select size="small" v-model="role" @change="onCurrentPageChange(1)"
        class="roleSelecter"  popper-class="el-select-pannel" clearable
        placeholder="角色">
        <el-option
          v-for="item in roleList"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-select size="small" v-model="stat" @change="onCurrentPageChange(1)"
        class="statSelecter"  popper-class="el-select-pannel" clearable
        placeholder="状态">
        <el-option
          v-for="item in statList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-table :data="page.list" element-loading-text="Loading..."
      size="small"
      border fit highlight-current-row
      header-row-class-name="tableHeader"
      :height="tableHeight"
      :row-class-name="tableRowClass"
      row-key="id"
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column fixed align="center" label="Id" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="名称" width="130" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.upid > 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <i :class="'iconfont '+scope.row.icon"></i>
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="username" width="160" align="left">
        <template slot-scope="scope">
          {{ scope.row.username}}
        </template>
      </el-table-column>
      <el-table-column label="enable" width="160" align="center">
        <template slot-scope="scope">
          <el-switch
            style="display: block"
            v-model="scope.row.enable"
            @change="handleEnable(scope.$index, scope.row)"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="roles" align="left">
        <template slot-scope="scope">
          <el-tag
            class="role-tag"
            :key="tag"
            v-for="tag in rowRoles(scope.row.roles)"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{tag}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="role" width="70" align="left">
        <template slot-scope="scope">
          <span>
            {{ scope.row.role}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="plat" width="70" align="left">
        <template slot-scope="scope">
          {{ scope.row.plat}}
        </template>
      </el-table-column>
      <el-table-column label="atime" width="160" align="left" class="hidden-md-and-down">
        <template slot-scope="scope">
          {{util.formatDate(scope.row.atime)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center" fixed="right">
        <template slot-scope="scope">
          <icon-btn @click="handleChangePwd(scope.row)" tip="修改密码"
            icon="iconxiugaimima"
            type="warning"
            ></icon-btn>
            <icon-btn @click="handleEdit(scope.$index, scope.row)" tip="修改"
            icon="iconedit1"
            type="warning"
            ></icon-btn>
          <el-popconfirm
            confirm-button-text='删除'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            @confirm="handleDelete(scope.$index, scope.row)"
            :title="'确定删除用户：'+scope.row.name+' 吗？'"
          >
            <icon-btn slot="reference" tip="删除"
            icon="icondel1"
            type="error"
            ></icon-btn>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
      <el-pagination
        @size-change="onPageSizeChange"
        @current-change="onCurrentPageChange"
        :current-page="page.currentPage"
        :page-sizes="[20, 30, 40, 50,100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
      <div class="pager-time" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
    </div>
    <!--新增-->
    <el-dialog :title="dialogTitle"
      width="36em"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      >
      <el-dialog
        width="30em"
        title="选择角色"
        :visible.sync="innerVisible"
        append-to-body>
        <div class="role-cards">
          <template v-for="(item, idx) in roleList">
            <div class="role-card" @click="handleFormAddRoleOk(item)" v-if="mform.roles.indexOf(item) == -1" :key="idx">{{item}}</div>
          </template>
        </div>
      </el-dialog>
        <el-form class="from"
          :model="mform"
          :label-position="labelPosition"
          :status-icon="true"
          :show-message="true"
          :rules="mRules"
          ref="mform"
          :label-width="formLabelWidth"
        >
          <el-form-item class="item-label" label="名称"  prop="name">
            <el-input class="input input-name" v-model="mform.name" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item class="item-label" label="手机"  prop="username">
            <el-input class="input" v-model="mform.username" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item v-if="!mform.id" class="item-label" label="密码"  prop="pwd">
            <el-input class="input input-pwd"
              v-model="mform.pwd"
              :show-password="true"
              type="password" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item class="item-label" label="状态" prop="stat">
            <el-switch
              class="switch"
              v-model="mform.enable"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="启用"
              inactive-text="禁用">
            </el-switch>
          </el-form-item>
          <el-form-item class="item-label" label="角色"  prop="roles">
            <el-tag
              class="role-tag"
              :key="tag"
              v-for="tag in mform.roles"
              closable
              :disable-transitions="false"
              @close="handleRemoveFormRole(tag)">
              {{tag}}
            </el-tag>
            <icon-btn @click="handleFormAddRole" icon="iconadd" tip="添加角色"></icon-btn>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" v-ripple>取 消</el-button>
          <el-button type="primary" @click="submitAddForm('mform')" v-ripple>确 定</el-button>
        </div>
    </el-dialog>
    <!--修改密码-->
    <el-dialog title="修改密码"
      width="30em"
      :visible.sync="dialogChangePwdVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      >
        <el-form class="from"
        :model="pwdform"
        label-position="left"
        :status-icon="true"
        :show-message="true"
        :rules="mPwdRules"
        ref="pwdForm"
        label-width="4em"
      >
        <el-form-item class="item-label" label="名称"  prop="name">
          <el-input class="input input-name" disabled v-model="pwdform.name" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item class="item-label" label="密码"  prop="pwd">
          <el-input class="input input-pwd"
            v-model="pwdform.pwd"
            :show-password="true"
            type="password" autocomplete="off" ></el-input>
        </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogChangePwdVisible = false" v-ripple>取 消</el-button>
          <el-button type="primary" @click="submitChangePwdForm('pwdForm')" v-ripple>确 定</el-button>
        </div>
    </el-dialog>
    <div v-if="refresh"></div>
  </div>
</template>

<script>
import IconBtn from '../../components/btns/IconBtn.vue';
export default {
  components: {
    IconBtn
  },
  data() {
    return {
      refresh: false,
      skey: '',
      page: {pg: 1, list:[], total: 0, pageSize: 20},
      role: '',
      roleList: ['ADMIN', 'MGR', 'USER'],
      stat: '',
      statList: [
        {value: 1, label: '启用'},
        {value: 0, label: '禁用'},
      ],
      tableHeight: 560,
      queryTime: '',
      //新增用户
      mform: {
        name: '',
        username: '',
        pwd: '',
        enable: 1,
        roles: [],
      },
      mRules: {
        name: [
          { required: true, message: '请输入模块名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 6 个字符', trigger: 'blur' },
        ],
        username: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1(3|4|5|6|7|8)\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          }
        ],
        pwd: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
        ]
      },
      dialogFormVisible: false,
      labelPosition: 'left',
      formLabelWidth: '4em',
      dialogTitle: '新增用户',
      innerVisible: false,
      //修改密码
      dialogChangePwdVisible: false,
      pwdform: {
        pwd : '',
      },
      mPwdRules: {
        pwd: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          }
        ]
      }
    };
  },//data
  created(opt) {
    this.resize();
    window.onresize = this.resize();
    this.queryRoles()
    this.onCurrentPageChange(1);
  }, //created
  methods: {
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    handleNew() { //新增用户
      this.mform = {
        name: '',
        username: '',
        pwd: '',
        enable: 1,
        roles: [],
      }
      this.dialogFormVisible = true
    },
    handleChangePwd(row) {
      this.pwdform.id = row.id
      this.pwdform.name = row.name
      this.dialogChangePwdVisible = true
    },
    submitChangePwdForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.post('api/sys/userChangePwd', this.pwdform, res=>{
            if (res.code != 0) {
              this.$message.error('操作失败~' + res.msg)
              return
            }
            this.$message.success('修改密码成功~')
            this.dialogChangePwdVisible = false
          })
        } else {
           this.$message.error('error submit!!');
          return false;
        }
      })
    },
    handleEnable(rowIndex, row) {
      var pm = {
        id: row.id,
        stat: row.enable
      }
      this.post('api/sys/userEnable', pm, res=>{
        if (res.code != 0) {
          this.$message.error('操作失败~' + res.msg)
          row.enable = !row.enable
          return
        }
        this.$message.success('操作成功~')
      })
    },
    handleEdit(rowIndex, row) {
      this.mform = this.util.copyFrom(row, "id,name, username, enable")
      this.mform.roles = row.roles.split(',')
      this.dialogFormVisible = true
    },
    handleDelete(rowIndex, row) {
      var pm = {
        id: row.id
      }
      this.post('api/sys/userDelete', pm, res=>{
        if (res.code != 0) {
          this.$message.error('操作失败~' + res.msg)
          return
        }
        this.$message.success('删除成功~')
        this.page.list.splice(rowIndex, 1)
      })
    },
    handleFormAddRole() {
      this.innerVisible = true
    },
    handleFormAddRoleOk(role) {
      this.mform.roles.push(role)
      this.innerVisible = false;
    },
    handleRemoveFormRole(role) {
      this.mform.roles.remove(role)
      this.refresh = !this.refresh
    },
    submitAddForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.post('api/sys/userEdit', this.mform, res=>{
            if (res.code != 0) {
              this.$message.error('操作失败~' + res.msg);
              return;
            }
            this.$message.success('保存成功~')
            if (this.mform.id) {
              var idx = this.util.indexOf(this.page.list, this.mform.id);
              if (idx >= 0) {
                // this.page.list[idx] = res.data
                this.page.list.splice(idx, 1, res.data)
                console.log(res.data)
                // this.$nextTick(()=>{
                // this.refresh = !this.refresh
                // })
              }
            } else {
              this.page.list.unshift(res.data)
            }
            this.dialogFormVisible = false
          })
        } else {
          this.$message.error('error submit!!');
          return false;
        }
      })
    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        pg : pg,
        pageSize: this.page.pageSize,
        skey: this.skey,
        role: this.role,
        stat: this.stat
      }
      this.post('api/sys/users', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        this.page = res.data
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    queryRoles() {
      this.post('api/sys/roles', {}, res=>{
        if (res.code != 0) {
          this.$message.error('获取角色信息失败：' + res.msg)
          return
        }
        var list = res.data
        this.roleList = []
        list.forEach(element => {
          this.roleList.push(element.id)
        });
      })
    },
    transStat(stat) {
      var res = ''
      switch(stat) {
        case 0:
          res = '禁用'
          break;
        case 1:
          res = '正常'
          break;
      }
      return res
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    rowRoles(roleStr){
      if (roleStr) {
        return roleStr.split(',')
      } else {
        return '';
      }
    }
  },//methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .roleSelecter {
    width: 8em;
  }

  .statSelecter{
    width: 7em;
  }
}
.role-tag {
  margin-left: 3px;
}
.from{
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-around;

  .item-label{
    text-align: left;
    margin-top: 3px;
  }

  .input{
    width: 20em;
    text-align: left;
  }

  .input-name{
    width: 15em;
  }

  .input-pwd{
    width: 15em;
  }
  .textarea{
    margin-left: 5px;
  }
  .switch{
    text-align: left;
    margin-top: 5px;
    width: 20em;
  }
  .role-tag + .role-tag {
    margin-left: 10px;
  }
}

.role-cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .role-card {
    flex: 0 1;
    white-space: nowrap;
    padding: 3px 5px;
    margin: 5px;
    width: 10em;
    border: $border;
    cursor: pointer;
    border-radius: 5px;
  }

  .role-card:hover {
    background-color: $dark;
    color: $text
  }
}
</style>
